import router from '../router'
import Deferred from '@/helpers/Deferred'
//import Cookies from 'js-cookie'

const NOTIFICATION_DURATION = 4500; // how long to display notifications

const UI = {
  namespaced: true,
  state: {
    notifications: [
    ],
    dialogQueue: [],
    pageTitle: "",
    jobStages: [],
    jobBillingStages: [],
    contextMenu: null,
  },
  getters: {
    NOTIFICATIONS: state => state.notifications,
    DIALOG_QUEUE_LENGTH: state => state.dialogQueue.length,
    PAGE_TITLE: state => state.pageTitle,
    JOB_STAGES: state => state.jobStages,
    JOB_BILLING_STAGES: state => state.jobBillingStages,
    CONTEXT_MENU: state => state.contextMenu,
  },
  mutations: {
    addNotification(state, { message }){
      let notification = {
        text: message,
        key: Math.random(),
        age: Date.now(),
        show: true,
      };
      state.notifications.push(notification);
    },
    removeNotification(state, key){
      let index = state.notifications.findIndex(n => n.key == key);
      if(index < 0) return;
      if(state.notifications[index].show == true)
        state.notifications[index].show = false;
      else
        state.notifications.splice(index, 1);
    },
    clearNotifications(state){
      state.notifications = [];
    },
    queueDialog(state, { dialog, callback }){
      let deferred = new Deferred();
      state.dialogQueue.push({
        dialog,
        deferred,
      });
      callback(deferred.promise);
    },
    dialogAdvanceQueue(state){
      state.dialogQueue.splice(0,1);
    },
    setTitle(state, title){
      state.pageTitle = title;
    },
    setJobStages(state, stages){
      state.jobStages = stages;
    },
    setJobBillingStages(state, stages){
      state.jobBillingStages = stages;
    },
    setContextMenu(state, component){
      state.contextMenu = component;
    }
  },
  actions: {
    NOTIFY({commit}, notification){
      if(typeof(notification) == "string")
        notification = { message: notification };
      commit('addNotification', notification);
    },
    CLEAR_NOTIFICATIONS({commit}){
      commit('clearNotifications');
    },
    _tick({ state, commit, dispatch }){
      //iterate list backwards, preserving lower indexes as items are spliced out
      for(let i = state.notifications.length - 1; i >= 0; i--){
        let age = Date.now() - state.notifications[i].age;
        if(age >= NOTIFICATION_DURATION) commit('removeNotification', state.notifications[i].key);
      }
      setTimeout(()=>{ dispatch('_tick'); }, 250);
    },
    NAVIGATE({ commit }, page){
      commit;//('setTitle', '');
      router.push(page);
    },
    PROMPT({ commit }, { message="", title="", placeholder="" }){
      let dialog = { message, title, placeholder, type: 'prompt' };
      let promise = null;
      let callback = (p) => { promise = p};
      commit('queueDialog', { dialog, callback });
      return promise;
    },
    PIN({ commit }, { message="", title="" }){
      let dialog = { message, title, type: 'otp', otpLength: 4, otpType: 'pin' };
      let promise = null;
      let callback = (p) => { promise = p};
      commit('queueDialog', { dialog, callback });
      return promise;
    },
    ALERT({ commit }, { message="", title="", level="info" }){
      let dialog = { message, title, level, type: 'alert' };
      let promise = null;
      let callback = (p) => { promise = p};
      commit('queueDialog', { dialog, callback });
      return promise;
    },
    CONFIRM({ commit }, { message="", title="", yes="Yes", other=null, no="No" }){
      let dialog = { message, title, yes, other, no, type: 'confirm' };
      let promise = null;
      let callback = (p) => { promise = p};
      commit('queueDialog', { dialog, callback });
      return promise;
    },
    DIALOG_ADVANCE_QUEUE({ commit, state }){
      let queuedItem = state.dialogQueue[0];
      commit('dialogAdvanceQueue');
      return queuedItem;
    },
    SET_TITLE({ commit }, title){
      commit('setTitle', title);
    },
    REFRESH_STAGES({ dispatch, commit }){
      dispatch('API/API', { endpoint: 'stages' }, { root: true })
      .then(stages => {
        commit('setJobStages', stages);
      });
    },
    REFRESH_BILLING_STAGES({ dispatch, commit }){
      dispatch('API/API', { endpoint: 'billing/stages' }, { root: true })
      .then(stages => {
        commit('setJobBillingStages', stages);
      });
    },
    SET_CONTEXT_MENU({ commit }, component){
      commit('setContextMenu', null); // close all (including current open)
      commit('setContextMenu', component); // then open chosen
    },
    init({dispatch}){
      dispatch('_tick');
      dispatch('REFRESH_STAGES');
      dispatch('REFRESH_BILLING_STAGES');
    }
  }
};

export default UI;
