<template>
  <div id="dialogs">

    <v-dialog v-model="dialogActive" v-if="type == 'prompt'" max-width="450" persistent>
      <v-card elevation="20">
        <v-card-title class="text-h6 primary white--text py-3">
          {{title}}
        </v-card-title>
        <v-card-text class='pb-0 pt-4 text-subtitle-2'>
          <p style='white-space: pre-wrap;'>{{message}}</p>
          <v-text-field ref='promptInput' v-model='promptInput' rounded outlined dense autofocus @keyup.enter='clickOK' />
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary darken-1" @click='clickCancel'>Cancel</v-btn>
          <v-btn color="primary darken-1" @click='clickOK'>OK</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogActive" v-if="type == 'otp'" max-width="450" persistent>
      <v-card elevation="20">
        <v-card-title class="text-h6 primary white--text py-3">
          {{title}}
        </v-card-title>
        <v-card-text class='pb-0 pt-4 text-subtitle-2'>
          <p style='white-space: pre-wrap;'>{{message}}</p>
          <form :onsubmit="clickOK">
            <input type="password" xstyle="display:none"/>
            <v-otp-input ref='otpInput' v-model='otpInput' rounded outlined autofocus
              :type="otpType=='pin'?'number':otpType"
              :class="{ passwordInput: otpType=='pin' }"
              :length="otpLength"
            />
          </form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary darken-1" @click='clickCancel'>Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogActive" v-if="type == 'alert'" max-width="450" persistent>
      <v-card elevation="20">
        <v-card-title class="text-h6 primary white--text py-3">
          {{title}}
        </v-card-title>
        <v-card-text class='pb-0 pt-4 text-subtitle-2'>
          <p style='white-space: pre-wrap;'>{{message}}</p>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary darken-1" @click='clickOK'>OK</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogActive" v-if="type == 'confirm'" max-width="450" persistent>
      <v-card elevation="20">
        <v-card-title class="text-h6 primary white--text py-3">
          {{title}}
        </v-card-title>
        <v-card-text class='pb-0 pt-4 text-subtitle-2'>
          <p style='white-space: pre-wrap;'>{{message}}</p>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary" @click='clickCancel'>{{no}}</v-btn>
          <v-btn color="primary darken-1" v-if="other" @click='clickOK("other")'>{{other}}</v-btn>
          <v-btn color="primary darken-1" @click='clickOK("yes")'>{{yes}}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </div>
</template>

<style lang="scss" scoped>
  .passwordInput::v-deep input{
    font-family: 'Password';
  }
</style>

<script>
import vue from 'vue'
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'Dialogs',

  components: {
  },

  data: () => ({
    dialogActive: false,
    type: "",
    title: "",
    message: "",
    promptInput: "",
    otpInput: "",
    level: "",
    deferred: null,
    yes: "",
    other: null,
    no: "",
    otpType: "",
    otpLength: 4,
  }),

  computed: {
    ...mapGetters("UI", ['DIALOG_QUEUE_LENGTH']),
  },

  watch: {
    DIALOG_QUEUE_LENGTH(){ this.checkDialogQueue(); },
    otpInput(){ if(this.otpInput.length == this.otpLength) this.clickOK(); }
  },

  methods:{
    ...mapActions("UI", ['DIALOG_ADVANCE_QUEUE']),

    checkDialogQueue(){
      if(this.DIALOG_QUEUE_LENGTH == 0) return; //nothing queued
      if(this.dialogActive) return; // dialog already open
      this.fetchNextDialog(); //next dialog please
    },

    fetchNextDialog(){
      this.DIALOG_ADVANCE_QUEUE()
      .then(({ deferred, dialog }) => {
        this.deferred = deferred;
        this.type = dialog.type;
        this.title = dialog.title;
        this.message = dialog.message;
        this.promptInput = dialog.placeholder;
        this.level = dialog.level;
        this.yes = dialog.yes ?? "Yes";
        this.other = dialog.other ?? null;
        this.no = dialog.no ?? "Cancel";
        this.otpType = dialog.otpType;
        this.otpLength = dialog.otpLength;
        this.otpInput = "";
        this.highlightPromptInput();
        this.highlightOtpInput();
        this.dialogActive = true;
      });
    },

    clickOK(choice){
      if(this.dialogActive){ //debounce double click
        if(this.type == 'prompt'){
          this.dialogActive = false;
          this.deferred.resolve(this.promptInput);
        }
        if(this.type == 'alert' || this.type == 'confirm'){
          this.dialogActive = false;
          this.deferred.resolve(choice);
        }
        if(this.type == 'otp'){
          this.dialogActive = false;
          this.deferred.resolve(this.otpInput);
        }
      }
    },

    clickCancel(){
      if(this.dialogActive){ //debounce double click
        this.dialogActive = false;
        this.deferred.reject();
      }
    },

    highlightPromptInput(){
      if(this.type != 'prompt') return;
      vue.nextTick(()=>{ // on next render (openened)
        setTimeout(() => { //wait longer (animations)
          this.$refs['promptInput'].$el.querySelector("input").select();
        }, 200);
      });
    },

    highlightOtpInput(){
      if(this.type != 'otp') return;
      vue.nextTick(()=>{ // on next render (openened)
        setTimeout(() => { //wait longer (animations)
          this.$refs['otpInput'].$el.querySelector("input").select();
        }, 200);
      });
    }
  },

  mounted() {
  },

};
</script>
