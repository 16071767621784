/*
 A deferred is special promise
 It exposes the promise, and the solve & reject functions
*/

class Deferred{
  constructor(){
    this.resolve = null;
    this.reject = null;
    this.promise = new Promise((resolve, reject) => {
      this.resolve = resolve;
      this.reject = reject;
    });
  }
}

export default Deferred;
