var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', _vm._l(_vm.NOTIFICATIONS, function (N, i) {
    return _c('div', {
      key: N.key,
      attrs: {
        "notification": N,
        "index": i
      }
    }, [_c('v-snackbar', {
      ref: "n-".concat(i),
      refInFor: true,
      class: {
        'slide': i !== _vm.lastAdded
      },
      attrs: {
        "value": N.show && !(_vm.justAdded && _vm.lastAdded == i),
        "timeout": "-1",
        "right": "",
        "multi-line": "",
        "transition": "slide-y-reverse-transition",
        "color": "#292929"
      }
    }, [_c('div', {
      staticStyle: {
        "white-space": "pre-wrap"
      }
    }, [_vm._v(_vm._s(N.text))])])], 1);
  }), 0);
}
var staticRenderFns = []

export { render, staticRenderFns }