import Vue from 'vue'
import Vuex from 'vuex'

import API from './API/API'
import AUTH from './AUTH'
import UI from './UI'

Vue.use(Vuex)

let vx = new Vuex.Store({ modules: { API, AUTH, UI } });
vx.dispatch('UI/init');

export default vx;
