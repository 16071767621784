const NETWORK_RETRY = window.app.config.NETWORK_RETRY ?? 3000;

//main class
class ApiQueue{

  queue = []; //the queue
  network_retry = false; // network offline?

  length(){ return this.queue.length }

  push(request){
    // cancel existing request if matching path is unsent
    if(request.autosave){
      let i = this.queue.findIndex(r => r.url == request.url && request.autosave && ['waiting', 'network fail'].includes(request.status));
      if(i >= 0) this.queue.splice(i, 1);
    }
    // queue request and then process the queue
    this.queue.push(request);
    this.process();
  }

  process(){
    // dispose of completed requests (iterate backwards, removing completed)
    for(let i=this.queue.length-1; i >= 0; i--){
      // we are going backwards so that indexes do not change upon splices
      if(this.queue[i].status == 'complete') this.queue.splice(i, 1);
    }

    //check for network errors in the queue
    this.network_retry = this.queue.findIndex(r => ['network fail', 'network fail retry'].includes(r.status)) >= 0;

    // process the request queue
    for(let i=0; i<this.queue.length; i++){

      let req = this.queue[i]; // the request being processed

      // trigger function for the request that processes the queue upon response
      let triggerRequest = () => {
        req.trigger().finally(() => { this.process(); });
      };

      // autosaves are scheduled for later if nothing else is waiting
      if(req.autosave > Date.now() && i == this.queue.length-1){
        setTimeout(() => {
          this.process(); // process queue 100ms after delay is due
        }, req.autosave < Date.now() + 100);
        break; // stop queue to avoid trigger below
      }

      // trigger if request is waiting
      if(req.status == 'waiting') {
        triggerRequest();
      }

      // "network failed" requests are scheduled for retry
      if(req.status == 'network fail') {
        req.status = 'network fail retry';
        setTimeout(() => { triggerRequest(); }, NETWORK_RETRY);
        break; //halt the queue until network fail is cleared
      }

      // halt the queue if this request alters data
      if(['PUT', 'POST', 'PATCH', 'DELETE'].includes(req.method)) break;

      // done, next request in the queue please....
    }
    // processing complete
  }



}

export default ApiQueue;
