var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    attrs: {
      "id": "dialogs"
    }
  }, [_vm.type == 'prompt' ? _c('v-dialog', {
    attrs: {
      "max-width": "450",
      "persistent": ""
    },
    model: {
      value: _vm.dialogActive,
      callback: function callback($$v) {
        _vm.dialogActive = $$v;
      },
      expression: "dialogActive"
    }
  }, [_c('v-card', {
    attrs: {
      "elevation": "20"
    }
  }, [_c('v-card-title', {
    staticClass: "text-h6 primary white--text py-3"
  }, [_vm._v(" " + _vm._s(_vm.title) + " ")]), _c('v-card-text', {
    staticClass: "pb-0 pt-4 text-subtitle-2"
  }, [_c('p', {
    staticStyle: {
      "white-space": "pre-wrap"
    }
  }, [_vm._v(_vm._s(_vm.message))]), _c('v-text-field', {
    ref: "promptInput",
    attrs: {
      "rounded": "",
      "outlined": "",
      "dense": "",
      "autofocus": ""
    },
    on: {
      "keyup": function keyup($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
        return _vm.clickOK.apply(null, arguments);
      }
    },
    model: {
      value: _vm.promptInput,
      callback: function callback($$v) {
        _vm.promptInput = $$v;
      },
      expression: "promptInput"
    }
  })], 1), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "color": "secondary darken-1"
    },
    on: {
      "click": _vm.clickCancel
    }
  }, [_vm._v("Cancel")]), _c('v-btn', {
    attrs: {
      "color": "primary darken-1"
    },
    on: {
      "click": _vm.clickOK
    }
  }, [_vm._v("OK")])], 1)], 1)], 1) : _vm._e(), _vm.type == 'otp' ? _c('v-dialog', {
    attrs: {
      "max-width": "450",
      "persistent": ""
    },
    model: {
      value: _vm.dialogActive,
      callback: function callback($$v) {
        _vm.dialogActive = $$v;
      },
      expression: "dialogActive"
    }
  }, [_c('v-card', {
    attrs: {
      "elevation": "20"
    }
  }, [_c('v-card-title', {
    staticClass: "text-h6 primary white--text py-3"
  }, [_vm._v(" " + _vm._s(_vm.title) + " ")]), _c('v-card-text', {
    staticClass: "pb-0 pt-4 text-subtitle-2"
  }, [_c('p', {
    staticStyle: {
      "white-space": "pre-wrap"
    }
  }, [_vm._v(_vm._s(_vm.message))]), _c('form', {
    attrs: {
      "onsubmit": _vm.clickOK
    }
  }, [_c('input', {
    attrs: {
      "type": "password",
      "xstyle": "display:none"
    }
  }), _c('v-otp-input', {
    ref: "otpInput",
    class: {
      passwordInput: _vm.otpType == 'pin'
    },
    attrs: {
      "rounded": "",
      "outlined": "",
      "autofocus": "",
      "type": _vm.otpType == 'pin' ? 'number' : _vm.otpType,
      "length": _vm.otpLength
    },
    model: {
      value: _vm.otpInput,
      callback: function callback($$v) {
        _vm.otpInput = $$v;
      },
      expression: "otpInput"
    }
  })], 1)]), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "color": "secondary darken-1"
    },
    on: {
      "click": _vm.clickCancel
    }
  }, [_vm._v("Cancel")])], 1)], 1)], 1) : _vm._e(), _vm.type == 'alert' ? _c('v-dialog', {
    attrs: {
      "max-width": "450",
      "persistent": ""
    },
    model: {
      value: _vm.dialogActive,
      callback: function callback($$v) {
        _vm.dialogActive = $$v;
      },
      expression: "dialogActive"
    }
  }, [_c('v-card', {
    attrs: {
      "elevation": "20"
    }
  }, [_c('v-card-title', {
    staticClass: "text-h6 primary white--text py-3"
  }, [_vm._v(" " + _vm._s(_vm.title) + " ")]), _c('v-card-text', {
    staticClass: "pb-0 pt-4 text-subtitle-2"
  }, [_c('p', {
    staticStyle: {
      "white-space": "pre-wrap"
    }
  }, [_vm._v(_vm._s(_vm.message))])]), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "color": "primary darken-1"
    },
    on: {
      "click": _vm.clickOK
    }
  }, [_vm._v("OK")])], 1)], 1)], 1) : _vm._e(), _vm.type == 'confirm' ? _c('v-dialog', {
    attrs: {
      "max-width": "450",
      "persistent": ""
    },
    model: {
      value: _vm.dialogActive,
      callback: function callback($$v) {
        _vm.dialogActive = $$v;
      },
      expression: "dialogActive"
    }
  }, [_c('v-card', {
    attrs: {
      "elevation": "20"
    }
  }, [_c('v-card-title', {
    staticClass: "text-h6 primary white--text py-3"
  }, [_vm._v(" " + _vm._s(_vm.title) + " ")]), _c('v-card-text', {
    staticClass: "pb-0 pt-4 text-subtitle-2"
  }, [_c('p', {
    staticStyle: {
      "white-space": "pre-wrap"
    }
  }, [_vm._v(_vm._s(_vm.message))])]), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "color": "secondary"
    },
    on: {
      "click": _vm.clickCancel
    }
  }, [_vm._v(_vm._s(_vm.no))]), _vm.other ? _c('v-btn', {
    attrs: {
      "color": "primary darken-1"
    },
    on: {
      "click": function click($event) {
        return _vm.clickOK("other");
      }
    }
  }, [_vm._v(_vm._s(_vm.other))]) : _vm._e(), _c('v-btn', {
    attrs: {
      "color": "primary darken-1"
    },
    on: {
      "click": function click($event) {
        return _vm.clickOK("yes");
      }
    }
  }, [_vm._v(_vm._s(_vm.yes))])], 1)], 1)], 1) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }