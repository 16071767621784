<template>
  <div>
    <div v-for='(N,i) in NOTIFICATIONS' v-bind:key='N.key' :notification='N' :index='i'>
        <v-snackbar
          :value="N.show && !(justAdded && lastAdded == i)"
          timeout="-1"
          :class="{'slide': i !== lastAdded }"
          right
          multi-line
          :ref='`n-${i}`'
          transition='slide-y-reverse-transition'
          color="#292929"
          >
          <div style='white-space: pre-wrap;'>{{N.text}}</div>
        </v-snackbar>
    </div>
  </div>
</template>

<style lang="scss" scoped>
</style>

<style lang="scss" scoped>
.slide{
  transition: margin 0.5s;
}
</style>

<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'
export default {
  name: 'NotificationSnacks',
  data: () => ({
    showInner: null,
    justAdded: false,
    lastAdded: null,
    count: 0,
  }),
  computed: {
    ...mapGetters('UI', ['NOTIFICATIONS']),
  },
  watch: {
    NOTIFICATIONS(N){
      this.justAdded = false;
      this.lastAdded = null;
      if(N.length > this.count){
        this.lastAdded = N.length - 1;
        this.justAdded = true;
      }
      this.count = N.length;
      Vue.nextTick(()=>{
        this.justAdded = false;
        let offset = 0;
        for(let i=0; i<N.length; i++){
          let e = this.$refs[`n-${i}`][0].$el;
          e.style.marginBottom = `${offset}px`;
          offset += e.children[0].getBoundingClientRect().height + 10;
        }
      });
    }
  },
  mounted(){
    this.showInner = this.show;
  }
};
</script>
