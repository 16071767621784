import axios from 'axios'
import Deferred from '@/helpers/Deferred'

//main class
class ApiRequest{

  constructor({ method, url, headers, data, progress = null, autosave = null }){
    this.method = method;
    this.url = url;
    this.headers = headers;
    this.data = data;
    this.autosave = autosave ? Date.now() + autosave : null;
    if(progress == null) this.progress = null;
    else this.progress = e => progress(Math.round((e.loaded * 100) / e.total));
    this.deferred = new Deferred();
    this.status = 'waiting';
  }

  trigger(){
    this.status = 'sent';
    return axios({
      method: this.method,
      url: this.url,
      headers: this.headers,
      data: this.data,
      responseType: 'json',
      onUploadProgress: this.progress,
    })
    .then(response => { // HTTP Request succeeded
      this.status = 'complete';
      if(response.data.success) this.deferred.resolve(response.data.data); //API Response
      else this.deferred.reject(response.data.message); //API Error
    })
    .catch(e => { // HTTP Failed
      if(e.response){
        // Probably a bad HTTP Status
        this.status = 'complete';
        this.deferred.reject("Something went wrong.");
      }
      else{
        // Probably a network error
        this.status = 'network fail';
      }
    });
  }

}

export default ApiRequest;
