import Cookies from 'js-cookie'
import Moment from 'moment'

function GetBrowserId(){
  let browserId = JSON.parse(Cookies.get('AUTH_BROWSER') ?? null) ?? "";
  if(!browserId){
    var chars = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    for(var i=0;i<64;i++) browserId += chars[Math.floor(Math.random() * chars.length)];
    Cookies.set('AUTH_BROWSER', JSON.stringify(browserId), {
      expires: new Moment().add(365, 'days').toDate()
    });
  }
  return JSON.parse(Cookies.get('AUTH_BROWSER') ?? null);
}

const AUTH = {
  namespaced: true,
  state: {
    token: JSON.parse(Cookies.get('AUTH_TOK') ?? null),
    pinTokens: [],
    permissions: [],
    browserId: GetBrowserId(),
    user: null,
  },
  getters: {
    LOGGED_IN(state){ return typeof(state.token) == "string";  },
    TOKEN(state){ return state.token; },
    PERMS(state){ return state.permissions; },
    BROWSER_ID(state){ return state.browserId; },
    USER(state){ return state.user; },
    USER_INITIALS(state){ return `${state.user.name_first[0]??''}${state.user.name_last[0]??''}`; },
  },

  mutations: {
    setToken(state, authToken){
      state.token = authToken;
      Cookies.set('AUTH_TOK', JSON.stringify(authToken));
    },
    clearToken(state){
      state.token = null;
      Cookies.remove('AUTH_TOK');
    },
    setPermissions(state, permissions){ state.permissions = permissions; },
    setUser(state, user){ state.user = user; },
  },
  actions: {
    LOGIN({commit}, authtoken){
      commit('setToken', authtoken);
    },
    LOGOUT({commit, dispatch}){
      commit('clearToken');
      dispatch('UI/NAVIGATE', '/login', { root: true })
    },
    SET_PERMS({commit}, permissions){ commit('setPermissions', permissions) },
    SET_USER({commit}, user){ commit('setUser', user) },
  }
}

export default AUTH;
