import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const views = {
  Login:      () => import(/* webpackChunkName: "login" */ '../views/Login'),
  Dashboard:  () => import(/* webpackChunkName: "dashboard" */ '../views/Dashboard'),
  Customers:  () => import(/* webpackChunkName: "crm" */ '../views/Customers/Customers'),
  Customer:   () => import(/* webpackChunkName: "customer" */ '../views/Customers/Customer'),
  Jobs:  () => import(/* webpackChunkName: "jobs" */ '../views/Jobs/Jobs'),
  Job:   () => import(/* webpackChunkName: "job" */ '../views/Jobs/Job'),
  Filebox:  () => import(/* webpackChunkName: "filebox" */ '../views/Filebox'),
  ArrowTest:  () => import(/* webpackChunkName: "arrowtest" */ '../views/ArrowTest'),
  MarkupTest:  () => import(/* webpackChunkName: "markupTest" */ '../views/MarkupTest'),
  schedule_settings: () => import(/* webpackChunkName: "schedule_settings" */ '../views/Settings/Schedule'),
  TaskSchedule: () => import(/* webpackChunkName: "TaskSchedule" */ '../views/Task/Schedule'),
  MyWork: () => import(/* webpackChunkName: "MyWork" */ '../views/Task/ScheduleViewer'),
};

const routes = [
  { path: '/', redirect: '/dash' },
  { path: '/markupTest', props: true, component: views.MarkupTest, children: [], },
  { path: '/login/:resetCode?', props: true, component: views.Login, children: [], },
  { path: '/dash', component: views.Dashboard, children: [
      { path: 'crm', component: views.Customers, children: [] },
      { path: 'crm/:custId', props: true, component: views.Customer, },
      { path: 'jobs', redirect: 'jobs/all', },
      { path: 'jobs/:stageId', props: true, component: views.Jobs },
      { path: 'jobs/:stageId/:jobId', props: true, component: views.Job },
      {
        path: 'settings/:_p2?', component: () => import(/* webpackChunkName: "settings" */ '../views/Settings/Settings'), children: [
          { path: 'about', component: () => import(/* webpackChunkName: "about" */ '../views/Settings/About') },
          { path: 'authentication', component: () => import(/* webpackChunkName: "settings_authentication" */ '../views/Settings/Authentication') },
          { path: 'users', component: () => import(/* webpackChunkName: "users" */ '../views/Settings/UserSettings') },
          { path: 'stages', component: () => import(/* webpackChunkName: "stages" */ '../views/Settings/Stages') },
          { path: 'billing_stages', component: () => import(/* webpackChunkName: "stages" */ '../views/Settings/BillingStages') },
          { path: 'measure_options', component: () => import(/* webpackChunkName: "measure_options" */ '../views/Settings/MeasureOptions') },
          { path: 'schedule', component: views.schedule_settings },
          { path: 'customers', component: () => import(/* webpackChunkName: "settings_customers" */ '../views/Settings/Customers') },
        ]
      },
      { path: 'filebox', props: true, component: views.Filebox, children: [], },
      { path: 'arrowTest', props: true, component: views.ArrowTest, children: [], },
      { path: 'markupTest', props: true, component: views.MarkupTest, children: [], },
      { path: 'schedule', props: true, component: views.TaskSchedule, children: [], },
      { path: 'work', props: true, component: views.MyWork, children: [], },
    ]
  },

]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
