<template>
  <v-app>
    <NotificationSnacks />
    <Dialogs />
    <router-view />
  </v-app>
</template>

<style>
::-webkit-scrollbar{
width: 6px;
height: 4px;
}
::-webkit-scrollbar-thumb{
background: #A8A6A8;
border-radius: 3px;
}
::-webkit-scrollbar-thumb:hover{
background: #878587;
}
::-webkit-scrollbar-track{
background: #CCC0;
border-radius: 0px;
padding:2px;
}
.container.scroll{
  height: 100%;
  overflow-y: auto;
}
</style>

<script>
import { mapActions, mapGetters } from 'vuex';
import NotificationSnacks from '@/components/core/NotificationSnacks'
import Dialogs from '@/components/core/Dialogs'
export default {
  name: 'App',
  components: {
    NotificationSnacks, Dialogs,
  },
  data: () => ({
    //
  }),
  computed: {
    ...mapGetters('UI', ['PAGE_TITLE']),
  },
  watch:{
    $route: { handler(){ this.SET_TITLE(""); }, immediate: true },
    PAGE_TITLE(title){
      if(title) title += ' | ';
      document.title = title + "Mount Glass & Glazing";
    },
  },
  methods:{
    ...mapActions("UI", ['SET_TITLE']),
  }

};
</script>
