import ApiRequest from './ApiRequest'
import ApiQueue from './ApiQueue'

//const settings
//const RETRY_MILLIS = 1000;
//const API_SERVER = 'http://localhost:8000';

const API = {
  namespaced: true,
  state: {
    api_queue: new ApiQueue(),
  },
  getters: {
    API_BUSY(state){ return state.api_queue.queue.length },
    API_OFFLINE(state){ return state.api_queue.network_retry },
  },

  mutations: {

    api_queue_request(state, request) {
      state.api_queue.push(request);
    },

  },
  actions: {

    API( {commit, rootGetters, dispatch}, { method, endpoint, data, autosave, progress } ){
      let headers = { Authorization: `Bearer ${rootGetters['AUTH/TOKEN']}` };
      let url = `${window.app.config.API}/${endpoint}`;
      let rq = new ApiRequest({ method, url, data, autosave, headers, progress });
      commit('api_queue_request', rq);
      rq.deferred.promise.catch(message => dispatch('UI/NOTIFY', message, { root: true }));
      return rq.deferred.promise;
    },

  }
}

export default API;
